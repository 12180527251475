import * as React from "react";
import { DownloadIcon } from "./svg";

const DownloadBox = ({ text, url }) => (
  <a href={url} target="_blank" rel="noreferrer">
    <div className="bg-gray-100 sm:p-8 p-4 sm:mt-16 mt-8">
      <div className="flex justify-start items-center">
        <DownloadIcon className="flex-none w-auto h-8 sm:h-12 mr-4 sm:mr-6" />
        <span className="text-blue-800 flex-shrink">{text}</span>
      </div>
    </div>
  </a>
);

export default DownloadBox;
